import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faBell, faHistory, faChartColumn } from '@fortawesome/free-solid-svg-icons';
import { faTachometerAlt, faUser, faClipboardList, faCertificate, faTasks, faStar, faSignOutAlt, faBook } from '@fortawesome/free-solid-svg-icons';
import { oratricslogo } from "../Assets/index";
import { IconButton, Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Box, Drawer, useMediaQuery, useTheme, ListItemButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const menuItems = [
  { name: "Dashboard", icon: faTachometerAlt, link: "/" },
  { name: "Class Summary", icon: faUser, link: "/classsummary" },
  { name: "My Curriculum", icon: faBook, link: "/curriculum" },
  { name: "Student Daily Performance", icon: faChartColumn, link: "/performance" },
  {
    name: "Activity Classes",
    icon: faClipboardList,
    submenu: [
      { name: "Activity Class", link: "/activityclass" },
      { name: "Saved Activity Classes", link: "/viewnotes" }
    ]
  },
  { name: "Achievements", icon: faCertificate, link: "/achievements" },
  { name: "Assessments", icon: faTasks, link: "/assessments" },
  { name: "My Rewards", icon: faStar, link: "/reward" },
  { name: "My Class History", icon: faHistory, link: "/history" },
];

const ManubarPage = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleItemClick = (item) => {
    if (item.submenu) {
      setOpenSubmenu(openSubmenu === item ? null : item);
    } else {
      setSelectedItem(item);
      setShowMenu(false);
      navigate(item.link);
    }
  };

  const handleLogoutClick = () => {
    setOpenLogoutDialog(true);
  };

  const handleLogoutConfirm = () => {
    setOpenLogoutDialog(false);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate("/login");
  };

  const handleLogoutCancel = () => {
    setOpenLogoutDialog(false);
  };

  return (
    <>
      {/* Mobile Header Navbar */}
      <Box sx={{
  display: { xs: 'flex', lg: 'none' },
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 100,
  bgcolor: "#fff",
  boxShadow: 3,
  p: 2,
  justifyContent: 'space-between',
  alignItems: 'center',
}}>
  <IconButton onClick={() => setShowMenu(!showMenu)} aria-label="Toggle menu">
    <FontAwesomeIcon icon={showMenu ? faTimes : faBars} style={{ color: '#111' }} />
  </IconButton>
  <img src={oratricslogo} alt="Oratrics Logo" style={{ height: '48px' }} />
</Box>


      {/* Mobile Sidebar Menu */}
      <Drawer
        anchor="left"
        open={showMenu}
        onClose={() => setShowMenu(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: 240,
            padding: '20px 15px',
            bgcolor: 'white', // Set background to white
            zIndex: 40,
            borderRadius: 4,
            boxShadow: 4,
            transition: 'all 0.3s ease-in-out',
          },
        }}
      >
        <List>
          {menuItems.map((item, index) => (
            <div key={index}>
              <ListItemButton onClick={() => handleItemClick(item)} sx={{
                '&:hover': { backgroundColor: theme.palette.action.hover },
                color: theme.palette.text.primary, // Use dark text for contrast
              }}>
                <FontAwesomeIcon icon={item.icon} style={{ marginRight: 16, color: theme.palette.primary.main }} />
                <ListItemText primary={item.name} sx={{ color: theme.palette.text.primary }} />
              </ListItemButton>
              {item.submenu && openSubmenu === item && (
                <List sx={{ paddingLeft: 4 }}>
                  {item.submenu.map((subItem, subIndex) => (
                    <ListItemButton key={subIndex} onClick={() => handleItemClick(subItem)} sx={{
                      '&:hover': { backgroundColor: theme.palette.action.hover },
                      color: theme.palette.text.primary, // Use dark text for submenu
                    }}>
                      <ListItemText primary={subItem.name} sx={{ color: theme.palette.text.primary }} />
                    </ListItemButton>
                  ))}
                </List>
              )}
            </div>
          ))}
        </List>

        <Divider sx={{ marginTop: 2 }} />

        <ListItemButton onClick={handleLogoutClick} sx={{
          '&:hover': { backgroundColor: theme.palette.action.hover },
          color: theme.palette.text.primary, // Dark color for logout
        }}>
          <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: 16, color: theme.palette.primary.main }} />
          <ListItemText primary="Logout" sx={{ color: theme.palette.text.primary }} />
        </ListItemButton>
      </Drawer>

      {/* Desktop Sidebar Menu */}
      <Box
        sx={{
          display: { xs: 'none', lg: 'block' },
          width: 240,
          bgcolor: 'white', // Set background to white
          height: '100vh',
          position: 'sticky',
          top: 0,
          boxShadow: 3,
          padding: 3,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 3 }}>
          <img src={oratricslogo} alt="Oratrics Logo" style={{ height: '64px' }} />
        </Box>

        <List>
          {menuItems.map((item, index) => (
            <div key={index}>
              <ListItemButton onClick={() => handleItemClick(item)} sx={{
                paddingLeft: 2,
                '&:hover': { backgroundColor: theme.palette.action.hover },
                color: theme.palette.text.primary, // Dark text color for items
                transition: 'background-color 0.3s ease',
              }}>
                <FontAwesomeIcon icon={item.icon} style={{ marginRight: 16, color: theme.palette.primary.main }} />
                <ListItemText primary={item.name} sx={{ color: theme.palette.text.primary }} />
              </ListItemButton>
              {item.submenu && openSubmenu === item && (
                <List sx={{ paddingLeft: 4 }}>
                  {item.submenu.map((subItem, subIndex) => (
                    <ListItemButton key={subIndex} onClick={() => handleItemClick(subItem)} sx={{
                      '&:hover': { backgroundColor: theme.palette.action.hover },
                      color: theme.palette.text.primary, // Dark text color for subitems
                    }}>
                      <ListItemText primary={subItem.name} sx={{ color: theme.palette.text.primary }} />
                    </ListItemButton>
                  ))}
                </List>
              )}
            </div>
          ))}
        </List>

        <Divider sx={{ marginTop: 2 }} />

        <ListItemButton onClick={handleLogoutClick} sx={{
          paddingLeft: 2,
          '&:hover': { backgroundColor: theme.palette.action.hover },
          color: theme.palette.text.primary, // Dark color for logout
        }}>
          <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: 16, color: theme.palette.primary.main }} />
          <ListItemText primary="Logout" sx={{ color: theme.palette.text.primary }} />
        </ListItemButton>
      </Box>

      {/* Logout Confirmation Dialog */}
      <Dialog
        open={openLogoutDialog}
        onClose={handleLogoutCancel}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title" sx={{ backgroundColor: theme.palette.primary.main, color: 'white' }}>
          Confirm Logout
        </DialogTitle>
        <DialogContent>
          <p id="logout-dialog-description">Are you sure you want to log out? You will be redirected to the login page.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogoutConfirm} color="secondary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManubarPage;
