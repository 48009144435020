import React, { useEffect, useState } from 'react';
import {
  Grid,
  CircularProgress,
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Chip,
  LinearProgress,
  Button,
  IconButton,
  Avatar,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LinkIcon from '@mui/icons-material/Link';
import axios from 'axios';
import baseUrl from '../config';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '30px',
  border: '2px solid #00796b',
  color: '#00796b',
  padding: '12px 30px',
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '16px',
  '&:hover': {
    backgroundColor: '#e0f2f1',
    borderColor: '#004d40',
  },
}));

const TeacherName = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: '#004d40',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
}));

const DayOfWeekStyled = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: '#ffffff',
  backgroundColor: '#004d40',
  borderRadius: '12px',
  padding: '5px 15px',
  textTransform: 'uppercase',
  display: 'inline-block',
  fontSize: '14px',
  letterSpacing: '0.5px',
}));

const TotalClassesBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '20px',
  right: '20px',
  padding: '10px 20px',
  backgroundColor: '#004d40',
  color: 'white',
  borderRadius: '30px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  fontWeight: 700,
  fontSize: '18px',
  textAlign: 'center',
}));

const ClassCard = ({ classData, isUpcoming, isNextClass, index }) => {
  const { startDate, joinUrl, title, time, zoomMeetingId, teacherName, teacherAvatar, dayOfWeek } = classData || {};
  const isClassOver = new Date(startDate) < new Date();

  return (
    <Card sx={{
      boxShadow: 5,
      borderRadius: 3,
      transition: 'transform 0.3s ease, box-shadow 0.3s',
      backgroundColor: isClassOver ? '#e0f7fa' : '#ffffff',
      '&:hover': !isClassOver && {
        transform: 'scale(1.05)',
        boxShadow: 10,
      },
      border: '1px solid #00796b',
    }}>
      <CardHeader
        title={`${title || 'Class'} #${index}`}
        subheader={
          <DayOfWeekStyled>
            {dayOfWeek.join(", ")} {/* Display full list of days or adjust as needed */}
          </DayOfWeekStyled>
        }
        action={<Chip label={isClassOver ? 'Class Over' : 'Upcoming'} color={isClassOver ? 'error' : 'success'} />}
        sx={{
          backgroundColor: '#004d40',
          color: 'white',
          borderRadius: '8px 8px 0 0',
          padding: '12px 20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      />
      <CardContent sx={{ padding: 3 }}>
        <Typography 
          variant="h6" 
          sx={{
            fontWeight: isNextClass ? 'bold' : 'normal',
            color: isNextClass ? '#FF5733' : '#004d40',
            marginBottom: 2,
          }}
        >
          {isNextClass ? 'Next Class' : 'Class Date'}: 
          <strong> {new Date(startDate).toLocaleDateString()}</strong>
        </Typography>

        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <AccessTimeIcon sx={{ marginRight: 1, color: '#004d40' }} />
          <Typography variant="body2" color="textSecondary">
            Class Time: {time}
          </Typography>
        </Typography>

        <TeacherName>
          <Avatar src={teacherAvatar} sx={{ width: 30, height: 30, marginRight: 1 }} />
          <Typography variant="body2" color="textSecondary">
            Teacher: {teacherName}
          </Typography>
        </TeacherName>

        <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 2 }}>
          <strong>Join URL: </strong>
          <a href={joinUrl} target="_blank" rel="noopener noreferrer">
            <IconButton sx={{ color: '#004d40', fontSize: '1.25rem' }}>
              <LinkIcon />
            </IconButton>
          </a>
        </Typography>

        {!isClassOver && (
          <LinearProgress
            variant="determinate"
            value={((new Date() - new Date(startDate)) / (1000 * 60 * 60)) * 100}
            sx={{
              marginTop: 2,
              borderRadius: 2,
              height: 6,
              backgroundColor: '#00796b',
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};

const ClassSummary = () => {
  const [classesData, setClassesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClassSchedules = async () => {
      try {
        const response = await fetch(`${baseUrl}api/students/enrolled-courses`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        });
        const data = await response.json();
        setClassesData(data[0].course.schedules || []);
      } catch (err) {
        setError(err?.response?.data?.message || 'An error occurred while fetching schedules');
      } finally {
        setLoading(false);
      }
    };

    fetchClassSchedules();
  }, []);

  if (loading) return (
    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  );
  if (error) return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', padding: 2 }}>
      <Alert severity="error">{error}</Alert>
    </Box>
  );

  const upcomingClasses = [];
  const pastClasses = [];
  let nextClass = null;

  // Separate classes into upcoming and past
  classesData.forEach((classData) => {
    if (new Date(classData.startDate) > new Date()) {
      upcomingClasses.push(classData);
    } else {
      pastClasses.push(classData);
    }
  });

  // Sort upcoming classes by start date to ensure next class is the earliest upcoming class
  if (upcomingClasses.length > 0) {
    nextClass = upcomingClasses.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))[0];
    const nextClassIndex = upcomingClasses.findIndex(c => c.classNumber === nextClass.classNumber);
    if (nextClassIndex !== -1) {
      upcomingClasses.splice(nextClassIndex, 1);
    }
  }

  const pastClassesCount = pastClasses.length;
  const nextClassNumber = pastClassesCount > 0 ? pastClassesCount + 1 : 1;

  return (
    <Box sx={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: 4,
      background: 'linear-gradient(135deg, #F8F8F8, #ffffff)',
      borderRadius: 4,
      boxShadow: 3,
      overflowY: 'auto',
      position: 'relative',
    }}>
      {/* Total Classes Box */}
      <TotalClassesBox>
        Total Classes: {classesData.length}
      </TotalClassesBox>

      <Typography variant="h4" gutterBottom align="center" sx={{
        fontWeight: 700,
        color: '#004d40',
        textTransform: 'uppercase',
        letterSpacing: 1,
        marginBottom: 4
      }}>
        Class Summary
      </Typography>

      <Typography variant="h5" sx={{ fontWeight: 700, marginBottom: 3, color: '#FF5733' }}>
        Next Class (No: {nextClassNumber})
      </Typography>

      <Grid container spacing={4}>
        {nextClass ? (
          <Grid item xs={12} sm={6} md={4} key={nextClass.classNumber}>
            <ClassCard classData={nextClass} isUpcoming={true} isNextClass={true} index={nextClassNumber} />
          </Grid>
        ) : (
          <Typography variant="h6" color="textSecondary" align="center" sx={{ width: '100%' }}>
            No upcoming classes
          </Typography>
        )}
      </Grid>

      <Typography variant="h5" sx={{ fontWeight: 700, marginTop: 5, marginBottom: 3, color: '#FF5733' }}>
        Upcoming Classes
      </Typography>
      <Grid container spacing={4}>
        {upcomingClasses.length > 0 ? (
          upcomingClasses.map((classData, index) => (
            <Grid item xs={12} sm={6} md={4} key={classData.classNumber}>
              <ClassCard classData={classData} isUpcoming={true} isNextClass={false} index={nextClassNumber + index + 1} />
            </Grid>
          ))
        ) : (
          <Typography variant="h6" color="textSecondary" align="center" sx={{ width: '100%' }}>
            No upcoming classes
          </Typography>
        )}
      </Grid>

      <Typography variant="h5" sx={{ fontWeight: 700, marginTop: 5, marginBottom: 3, color: '#FF5733' }}>
        Past Classes
      </Typography>
      <Grid container spacing={4}>
        {pastClasses.length > 0 ? (
          pastClasses.map((classData, index) => (
            <Grid item xs={12} sm={6} md={4} key={classData.classNumber}>
              <ClassCard classData={classData} isUpcoming={false} isNextClass={false} index={index + 1} /> {/* Start from 1 */}
            </Grid>
          ))
        ) : (
          <Typography variant="h6" color="textSecondary" align="center" sx={{ width: '100%' }}>
            No past classes
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default ClassSummary;
