const curriculumData = {

    "modules": [
      {
        "id": 1,
        "title": "Body Language Basics",
        "subtitle": "Stand Tall, Shine Bright: Master the Art of Body Language!",
        "topics": [
          {"number": 1, "title": "The Power of Spotlight: Speaking with Impact", "description": "Mastering Emphasis in Speech"},
          {"number": 2, "title": "The Power of Spotlight: Speaking with Impact", "description": "Speak with Clarity through Activities"},
          {"number": 3, "title": "Power of Pitch: Command Your Voice", "description": "Hit the Right Notes in Your Voice!"},
          {"number": 4, "title": "Inflection Magic: Adding Life to Words", "description": "Bring Your Words to Life through Activities"},
          {"number": 5, "title": "The Art of the Perfect Pause", "description": "Master the Moment: Silence that Speaks Volumes!"},
          {"number": 6, "title": "The Art of the Perfect Pause -2", "description": "Project Power and Presence through Activities"},
          {"number": 7, "title": "Eyes That Speak", "description": "Eye Contact"},
          {"number": 8, "title": "Expression Mastery: From Subtle to Spectacular", "description": "Facial Expression"},
          {"number": 9, "title": "Gesture Mastery: Speak Without Words", "description": ""},
          {"number": 10, "title": "Delivery using Body Gestures", "description": ""},
          {"number": 11, "title": "The Art of Reading People -1", "description": "Unlocking the Signals: Decoding Body Language"},
          {"number": 12, "title": "Practice Session", "description": ""}
        ]
      },
      {
        "id": 2,
        "title": "Building Confidence",
        "subtitle": "From Shy to Shine: Unlock the Power of Confidence!",
        "topics": [
          {"number": 13, "title": "Being a good listener", "description": "The Superpower: Understand, Empathize, Connect"},
          {"number": 14, "title": "Overcoming Shyness", "description": "Stepping into the Spotlight"},
          {"number": 15, "title": "Getting rid of stage fear", "description": "Owning the Stage with Confidence"},
          {"number": 16, "title": "Getting rid of stage fear- 2", "description": "Breaking the Spotlight Barrier through Activities"},
          {"number": 17, "title": "Personal Appearance", "description": "'First Impressions: Look Good, Feel Great!'"},
          {"number": 18, "title": "Personal Appearance", "description": "'Polished & Poised: Dress to Impress with Confidence!'"}
        ]
      },
      {
        "id": 3,
        "title": "Public Speaking Implementation",
        "subtitle": "Speak Up, Be Heard: Your Public Speaking Superpower!",
        "topics": [
          {"number": 19, "title": "Creating Stories", "description": "Crafting Imaginations"},
          {"number": 20, "title": "Story Narration & Practice", "description": "Narrative Mastery: Bringing Stories to Life"},
          {"number": 21, "title": "Speech – Speech Crafting Essentials", "description": ""},
          {"number": 22, "title": "Speech – Body", "description": ""},
          {"number": 23, "title": "Speech – Conclusion", "description": ""},
          {"number": 24, "title": "Debates – Basics", "description": "Debate Dynamics: Building the Foundation"},
          {"number": 25, "title": "Debates – Arguments & Rebuttals", "description": "Mastering the Art of Argument"},
          {"number": 26, "title": "Debates – Conclusion", "description": ""},
          {"number": 27, "title": "Let’s Practice Debates", "description": ""},
          {"number": 28, "title": "Delivering an impactful Presentation", "description": ""},
          {"number": 29, "title": "Practice Session -1", "description": ""},
          {"number": 30, "title": "Practice Session -2", "description": ""}
        ]
      },
      {
        "id": 4,
        "title": "Creative Writing",
        "subtitle": "Unleash Imagination: Craft Stories That Inspire!",
        "topics": [
          {"number": 31, "title": "Introduction to Creative Writing", "description": ""},
          {"number": 32, "title": "Character Development", "description": ""},
          {"number": 33, "title": "Plot & Structure", "description": ""},
          {"number": 34, "title": "Setting & Atmosphere", "description": ""},
          {"number": 35, "title": "Dialogues & Voice", "description": ""},
          {"number": 36, "title": "Exploring different Genres of Writing", "description": ""},
          {"number": 37, "title": "Poetry Writing", "description": ""},
          {"number": 38, "title": "Short Story Writing", "description": ""},
          {"number": 39, "title": "Creative non-fiction Writing", "description": ""},
          {"number": 40, "title": "Writing for different audiences", "description": ""},
          {"number": 41, "title": "Figurative language in writing", "description": ""},
          {"number": 42, "title": "Point of View & Purpose in Writing", "description": ""},
          {"number": 43, "title": "Essay Writing", "description": ""},
          {"number": 44, "title": "Letter Writing", "description": ""},
          {"number": 45, "title": "Narrative Writing", "description": ""},
          {"number": 46, "title": "Precise Writing", "description": ""},
          {"number": 47, "title": "Fun-Writing Prompts", "description": ""},
          {"number": 48, "title": "Fantasy Writing", "description": ""},
          {"number": 49, "title": "Mystery Writing", "description": ""},
          {"number": 50, "title": "Science Fiction Writing", "description": ""},
          {"number": 51, "title": "Adventure Stories", "description": ""},
          {"number": 52, "title": "Heroic Story Writing", "description": ""},
          {"number": 53, "title": "Playwriting (Basics)", "description": ""},
          {"number": 54, "title": "Playwriting (Advanced)", "description": ""},
          {"number": 55, "title": "Comic Book Writing -1", "description": ""},
          {"number": 56, "title": "Create your own comic & characters", "description": ""},
          {"number": 57, "title": "Email Writing", "description": ""},
          {"number": 58, "title": "Writing a Newsletter", "description": ""},
          {"number": 59, "title": "Publishing your Writing", "description": ""},
          {"number": 60, "title": "Picture Prompts", "description": ""},
          {"number": 61, "title": "Collaborative Writing -1", "description": ""},
          {"number": 62, "title": "Collaborative Writing -2", "description": ""}
        ]
      },
      {
        "id": 5,
        "title": "Advanced Oratory",
        "subtitle": "Words That Wow: Elevate Your Speaking Game!",
        "topics": [
          {"number": 63, "title": "Master of Persuasion", "description": ""},
          {"number": 64, "title": "The Art of Story Telling", "description": ""},
          {"number": 65, "title": "Advanced Argumentation-1", "description": ""},
          {"number": 66, "title": "Oratory for Impact: Speaking with Precision", "description": ""},
          {"number": 67, "title": "Handling difficult Questions & Rebuttals", "description": ""},
          {"number": 68, "title": "Stage Presence & Command", "description": ""},
          {"number": 69, "title": "Specialised Speaking Skills – (For Future Leaders)", "description": ""},
          {"number": 70, "title": "Specialised Speaking Skills – (For Future Entrepreneurs)", "description": ""},
          {"number": 71, "title": "Specialised Speaking Skills – (For Future Media Persons)", "description": ""},
          {"number": 72, "title": "Specialised Speaking Skills – (For Future Advocacy)", "description": ""},
          {"number": 73, "title": "Types of Speeches – (Extempore)", "description": ""},
          {"number": 74, "title": "Types of Speeches – (Declamation)", "description": ""},
          {"number": 75, "title": "Types of Speeches – (Description & Sales Pitch)", "description": ""},
          {"number": 76, "title": "Types of Speeches – (Ceremonial Speeches)", "description": ""},
          {"number": 77, "title": "Types of Speeches – (Oral Interpretation & Prose Duo)", "description": ""},
          {"number": 78, "title": "Types of Speeches – (MUN Speeches -1)", "description": ""},
          {"number": 79, "title": "Types of Speeches – (MUN Speeches -2)", "description": ""},
          {"number": 80, "title": "TEDx Foundation", "description": ""},
          {"number": 81, "title": "TEDx Performance", "description": ""},
          {"number": 82, "title": "Group Presentations", "description": ""}
        ]
      },
      {
        "id": 6,
        "title": "Social Skills & Etiquettes",
        "subtitle": "Manners Matter: The Art of Making Connections!",
        "topics": [
          {"number": 83, "title": "General Etiquettes", "description": "Manners that Matter"},
          {"number": 84, "title": "Social Interactions", "description": "The Art of Social Spark"},
          {"number": 85, "title": "Greetings & Introductions", "description": "Meet, Greet & Shine: Open Doors with Words"},
          {"number": 86, "title": "Phone & Online Etiquettes", "description": ""},
          {"number": 87, "title": "Handling Compliments & Criticism Gracefully", "description": ""},
          {"number": 88, "title": "Table Manners – 1", "description": "From Napkins to Seating"},
          {"number": 89, "title": "Table Manners – 2", "description": "Knife Fork & Grace"},
          {"number": 90, "title": "Table Manners – 3", "description": "Polished to the Last Bite"},
          {"number": 91, "title": "Old Money Etiquettes for Kids & Teens", "description": ""},
          {"number": 92, "title": "Conversational Skills -1", "description": "Break the Ice: Open Conversations with Ease"},
          {"number": 93, "title": "Conversational Skills -2", "description": "Mastering Small Talk"},
          {"number": 94, "title": "Conversational Skills -3", "description": "From HELLO to FRIENDSHIP"}
        ]
      },
      {
        "id": 7,
        "title": "Financial Literacy",
        "subtitle": "Money Smarts: Build Wealthy Habits Early!",
        "topics": [
          {"number": 95, "title": "Introduction to Personal Finances", "description": "Money Smart: Your Financial Adventure Begins"},
          {"number": 96, "title": "Budgeting made easy", "description": "Pocket Power: Learn to Lead Your Finances"},
          {"number": 97, "title": "Banking Systems & Debt", "description": "Smart Banking: From Systems to Solutions"},
          {"number": 98, "title": "Cash, Cards & Cheques", "description": "Mastering Modern Transactions"},
          {"number": 99, "title": "Savings & Investments-1", "description": "Save Today, Shine Tomorrow: The Power of Smart Saving!"},
          {"number": 100, "title": "Savings & Investments-2", "description": "Unlock the Secrets of Smart Investments!"},
          {"number": 101, "title": "Setting Financial Goals", "description": "Vision to Wealth"},
          {"number": 102, "title": "Credit Ratings & Scores", "description": ""},
          {"number": 103, "title": "Taxation-1", "description": "Taxes 101: Understanding Where Your Money Goes!"},
          {"number": 104, "title": "Taxation-2", "description": "Smart Tax Moves: Save More, Stress Less!"},
          {"number": 105, "title": "Entrepreneurship -1", "description": "Discover Your Big Business Idea!"},
          {"number": 106, "title": "Entrepreneurship -2", "description": "Plan Like a Pro: Craft Your Success Blueprint!"},
          {"number": 107, "title": "Entrepreneurship -3", "description": "How to start an Organisation?"},
          {"number": 108, "title": "Entrepreneurship -4", "description": "Hire & Fire: Build a strong team"},
          {"number": 109, "title": "Entrepreneurship -5", "description": "Sell Your Dream with Confidence!"},
          {"number": 110, "title": "Entrepreneurship -6", "description": "Advanced Business Terms"}
        ]
      },
      {
        "id": 8,
        "title": "Leadership Development",
        "subtitle": "Lead the Way: Empowering Young Trailblazers!",
        "topics": [
          {"number": 111, "title": "The Power of Smile", "description": ""},
          {"number": 112, "title": "Empathy, Gratitude & Mutual Respect", "description": "The Power Trio unleashed"},
          {"number": 113, "title": "Ethical Decision Making", "description": "Decisions with Integrity"},
          {"number": 114, "title": "Personal Branding", "description": "Crafting Your Unique Identity"},
          {"number": 115, "title": "Self-Leadership", "description": "Rule Yourself"},
          {"number": 116, "title": "Creativity & Critical Thinking -1", "description": "Unleash Your Genius: Think Outside the Box!"},
          {"number": 117, "title": "Creativity & Critical Thinking -2", "description": "Brain Power Boost: Solve, Analyse, Conquer!"},
          {"number": 118, "title": "Cultural & Global Awareness -1", "description": "Understanding Cultures: Embracing Diversity"},
          {"number": 119, "title": "Cultural & Global Awareness- 2", "description": "Global Citizenship: Connecting Across Borders"},
          {"number": 120, "title": "Words, Action & Power", "description": "Words in Motion: The Power of Action"},
          {"number": 121, "title": "Positivity & Motivational Mindset", "description": ""},
          {"number": 122, "title": "Examples & Case Studies of Successful Leaders", "description": ""}
        ]
      },
      {
        "id": 9,
        "title": "New Age Skills",
        "subtitle": "Future-Ready: Thrive in the Digital World!",
        "topics": [
          {"number": 123, "title": "Internet Safety & Cyber Security for Kids/Teens", "description": ""},
          {"number": 124, "title": "Artificial Intelligence -1", "description": "AI Basics Unleashed"},
          {"number": 125, "title": "Artificial Intelligence -2", "description": "Practical Usage"},
          {"number": 126, "title": "Artificial Intelligence -3", "description": "Generative AI"},
          {"number": 127, "title": "Youtubing -1", "description": "Lights, Camera, Action: Setting Up Your YouTube Studio!"},
          {"number": 128, "title": "Youtubing -2", "description": "Content Creation 101: Crafting Videos That Captivate!"},
          {"number": 129, "title": "Youtubing -3", "description": "Publish & Promote: Growing Your YouTube Audience!"},
          {"number": 130, "title": "Podcasting -1", "description": "Getting Started with Podcasting!"},
          {"number": 131, "title": "Podcasting -2", "description": "Recording, Editing & Publishing: Crafting Your Perfect Podcast!"},
          {"number": 132, "title": "Vlogging", "description": "Visual Storytelling & Digital Presence"},
          {"number": 133, "title": "Hosting", "description": "Own the Stage"},
          {"number": 134, "title": "Standup Comedy -1", "description": "Crafting Jokes That Land!"},
          {"number": 135, "title": "Standup Comedy -2", "description": "Stage Star: Delivering with Timing & Confidence!"},
          {"number": 136, "title": "Sports Commentary", "description": "Play-by-Play Mastery"},
          {"number": 137, "title": "Broadcast Journalism -1", "description": "Behind the Mic: Mastering the Art of Reporting"},
          {"number": 138, "title": "Broadcast Journalism -2", "description": "Lights, Camera, Action: On-Air Presence and Delivery"},
          {"number": 139, "title": "Mimicry", "description": "Mastering the Art of Voice & Expression"},
          {"number": 140, "title": "Acting -1", "description": "Power of Expression: Bring Your Emotions to Life!"},
          {"number": 141, "title": "Acting -2", "description": "The Art of Roleplay: Step into Any Character!"},
          {"number": 142, "title": "Interviewing Skills -1", "description": "Nail Your First Impression: Mastering the Interview Entrance!"},
          {"number": 143, "title": "Interviewing Skills -2", "description": "Answer Like a Pro: Acing Every Question with Confidence!"},
          {"number": 144, "title": "Future-Ready Skills", "description": "Hands-On Practice for Success"},
          {"number": 145, "title": "Skill Surge", "description": "Dynamic Activities for New-Age Excellence"}
        ]
      },
      {
        "id": 10,
        "title": "Health & Wellness for Kids & Teens",
        "subtitle": "Fit & Fabulous: Nurture Your Mind and Body!",
        "topics": []
      },
      {
        "id": 11,
        "title": "Anger Management",
        "subtitle": "Cool & Calm: Master the Art of Emotional Control!",
        "topics": []
      },
      {
        "id": 12,
        "title": "Career Counselling",
        "subtitle": "Dream Big, Plan Smart: Find Your Perfect Path!",
        "topics": []
      },
      {
        "id": 13,
        "title": "Sex Education",
        "subtitle": "Healthy Bodies, Confident Minds: Understanding Growing Up!",
        "topics": []
      },
      {
        "id": 14,
        "title": "Growth Mindset & Self-Love",
        "subtitle": "Think Big, Love Yourself: Build a Life You’re Proud Of!",
        "topics": []
      }
    ]
  }

  export default curriculumData
  