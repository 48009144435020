import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button, Card, CardContent } from '@mui/material';
import CountryTimeSelector from './TimeZone';
import CardComponent from './CardComponent';
import { newbg, newbg2, studentong } from '../Assets';
import baseUrl from '../config';

const Dashboards = () => {
  const [coursesData, setCoursesData] = useState([]);
  const [userName, setUserName] = useState('');

  const newtestperformance = async () => {
    try {
      const response = await fetch(`${baseUrl}api/students/performance`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      const data = await response.json();
      console.log(data, "datadata");
    } catch (error) {
      console.error('Error fetching performance:', error);
    }
  };

  const fetchUpcomingCourses = async () => {
    try {
      const response = await fetch(`${baseUrl}api/students/enrolled-courses`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      const data = await response.json();
      console.log(data, "Upcoming courses data");

      if (response.ok) {
        const transformedData = data.map(item => ({
          ...item,
          course: {
            ...item.course,
            title: item.course.title,
            description: item.course.description,
            startDate: item.course.startDate,
            endDate: item.course.endDate,
            bookedClasses: item.course.bookedClasses,
            completedClasses: item.course.completedClasses,
            progress: item.course.progress,
            teacherName: item.enrollmentTeacherName || item.teacherName || 'Unknown Teacher',
          }
        }));
        setCoursesData(transformedData);
      } else {
        console.error('Failed to fetch courses:', data.message);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const fetchUserDetails = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUserName(user.name || 'User');
    }
  };

  useEffect(() => {
    fetchUpcomingCourses();
    fetchUserDetails();
    newtestperformance();
  }, []);

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: { xs: 1, sm: 2 },
        backgroundImage: userName === "Heetika Malik" ? `url(${newbg2})` : `url(${newbg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // backgroundAttachment: 'fixed',
        overflowY: 'auto',
        transition: 'background-image 0.5s ease-in-out',
      }}
    >
      {/* Time Selector */}
      <Box
        sx={{
          backgroundColor: '#ffffff',
          padding: { xs: 1, sm: 2 },
          borderRadius: '16px',
          mb: 3,
          position: 'relative',
          zIndex: 1,
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
        }}
      >
        <CountryTimeSelector />
      </Box>

      {/* User greeting */}
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            color: '#1d3557', // Deep Blue for contrast on white background
          }}
        >
          👋 Welcome back, {userName}!
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: '#3a3a3a', // Soft gray for subtext
            fontSize: '1.2rem',
            mt: 1,
          }}
        >
          Take charge of your journey with Oratrics: The Personality School.
        </Typography>
      </Box>

      {/* Upcoming Courses Section */}
      <Typography
        variant="h5"
        sx={{
          mb: 2,
          color: '#1d3557', // Deep Blue for section titles
          fontWeight: 'bold',
          textShadow: '0px 0px 8px rgba(0, 0, 0, 0.3)',
        }}
      >
        Upcoming Classes
      </Typography>

      <Grid container spacing={3}>
        {coursesData.map(course => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={course.courseId}>
            {course.course ? (
              <CardComponent
                imageUrl={course.course?.imageUrl || studentong}
                title={course.course?.title}
                description={course.course?.description}
                pdfUrl={course.course?.pdfUrl}
                bookedClasses={course.course?.bookedClasses}
                teacher={course.course.enrollmentTeacherName}
                startDate={course.course.startDate}
                progress={course.course.progress}
                completed={course.course.completed}
                joinUrl={course.course.schedules[0]?.joinUrl}
              />
            ) : (
              <Card
                sx={{
                  padding: '20px',
                  borderRadius: '16px',
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#f8f9fa',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <CardContent>
                  <Typography variant="body2" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>No course information available.</Typography>
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Class Schedule:</Typography>
                    {course.course.schedules.map(schedule => (
                      <Box key={schedule._id} sx={{ mb: 1 }}>
                        <Typography variant="body2">
                          {`Date: ${new Date(schedule.startDate).toLocaleDateString()} | Time: ${schedule.time}`}
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          sx={{ textTransform: 'none', mt: 2, borderRadius: '8px' }}
                          href={schedule.joinUrl}
                          target="_blank"
                        >
                          Join Class
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Dashboards;
