import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { Suspense,useEffect } from 'react';

import {
  DashboardPage,
  Login,
  Register,

  
} from './Pages/index';
import TestScheduleMeeting from './Pages/TestScheduleMSg';
import CartificatePage from './Pages/CartificatePage';
import AssessmentsPage from './Pages/AssessmentsPage';
import ClassSummeryPage from './Pages/ClassSummeryPage';
import EnrichmentClassesPage from './Pages/EnrichmentClassesPage';
import RewardPage from './Pages/RewardPage';
import Layout from './Layout'; // Adjust import path as needed
import SalesProfileForm from './Pages/SalesProfileForm';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import PdfViewer from './Components/PdfViewer';
import PptViewer from './Components/PptViewer';
import StudentHistory from './Components/ClassHistory';
import { useRedirectIfAuthenticated } from './useAuth'; // Import redirect hook
import CreateNotes from './Components/CreateNotes';
import ViewNotes from './Components/ViewNotes';
import DailyPerformance from './Components/DailyPerformance';
import ProfilePage from './Components/ProfilePage';
import TeacherProfile from './Components/TeacherProfile';
import ShowCurriculum from './Components/ShowCurriculam';
import curriculumData from './json/Curiculam'; // Assuming curriculumData is in a separate file

const App = () => {
  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const tokenTimestamp = localStorage.getItem('tokenTimestamp');

    if (!token || !tokenTimestamp) {
      logout();
      return;
    }

    const currentTime = Date.now();
    const tokenAge = currentTime - parseInt(tokenTimestamp, 10);
    const fiveDaysInMilliseconds = 5 * 24 * 60 * 60 * 1000;

    if (tokenAge > fiveDaysInMilliseconds) {
      logout();
    }
  }, [logout]);

  return (
    <BrowserRouter>
              <Suspense fallback={<div>Loading...</div>}>

      <Routes>
        <Route path="/login" element={<LoginWithRedirect />} />
        {/* Uncomment if you have a registration page */}
        {/* <Route path="/register" element={<Register />} /> */}
        <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Layout />}>
            <Route index element={<DashboardPage />} />
            <Route path="/reward" element={<RewardPage />} />
            <Route path="/view-pdf" element={<PdfViewer />} />
            <Route path="/view-ppt" element={<PptViewer />} />
            <Route path="/activityclass" element={<EnrichmentClassesPage />} />
            <Route path="/classsummary" element={<ClassSummeryPage />} />
            <Route path="/assessments" element={<AssessmentsPage />} />
            <Route path="/achievements" element={<CartificatePage />} />
            <Route path="/assessmentss" element={<TestScheduleMeeting />} />
            <Route path="/sale" element={<SalesProfileForm />} />
            <Route path="/createNotes" element={<CreateNotes />} />
            <Route path="/viewnotes" element={<ViewNotes />} />
            <Route path="/performance" element={<DailyPerformance />} />
            <Route path="/profile" element={<ProfilePage />} />

            <Route path="/teacher/profile/:teacherId" element={<TeacherProfile/>} />

            <Route path="/Curriculum" element={<ShowCurriculum curriculumData={curriculumData} />} />

            <Route path="/history" element={<StudentHistory />} />
          </Route>
        </Route>
      </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

// Component to handle redirection if authenticated
const LoginWithRedirect = () => {
  useRedirectIfAuthenticated(); // Redirect if authenticated
  return <Login />;
};

export default App;
