import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Typography, Paper, Rating, Divider, Avatar, Grid, Button, TextField } from '@mui/material';
import baseUrl from '../config';

// TeacherProfile component
const TeacherProfile = () => {
  const { teacherId } = useParams(); 
  const [teacher, setTeacher] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(0);
  const [newRating, setNewRating] = useState(0);
  const [newReview, setNewReview] = useState('');
  const [hasReviewed, setHasReviewed] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchTeacherProfile = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/students/teacherprofile?teacherId=${teacherId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

        if (response.data.teacher) {
          setTeacher(response.data.teacher);
        }

        const fetchedReviews = response.data.teacher.reviews || [];
        setReviews(fetchedReviews);

        const avgRating = fetchedReviews.reduce((acc, review) => acc + review.rating, 0) / fetchedReviews.length;
        setRating(avgRating || 0);

        // Check if the student has already submitted a review
        const hasReviewed = fetchedReviews.some((review) => review.student === localStorage.getItem('userId'));
        setHasReviewed(hasReviewed);
      } catch (error) {
        console.error('Error fetching teacher profile:', error);
      }
    };

    fetchTeacherProfile();
  }, [teacherId]);

  const handleSubmitReview = async () => {
    if (newRating < 1 || newRating > 5) {
      alert('Rating must be between 1 and 5');
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}api/students/rate-teacher/${teacherId}`,
        { rating: newRating, review: newReview },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      alert(response.data.message);
      setReviews([...reviews, response.data.review]);
      setHasReviewed(true);  // Update the state to reflect the new review submission
    } catch (error) {
      console.error('Error submitting review:', error);
      alert('Error submitting review');
    }
  };

  if (!teacher) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#f0f4f8',
        padding: '40px 0',
      }}
    >
      <Paper
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: '15px',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
          width: '90%',
          maxWidth: '1000px',
          padding: '40px',
        }}
      >
        {/* Profile Header Section */}
        <Grid container spacing={3}>
          {/* Left Column: Avatar */}
          <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Avatar
              alt="Teacher Avatar"
              src={`${baseUrl}${teacher.avatar}`}
              sx={{
                width: 160,
                height: 160,
                borderRadius: '50%',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                border: '4px solid #fff',
              }}
            />
          </Grid>

          {/* Right Column: Teacher Info */}
          <Grid item xs={12} sm={8}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
              <Typography variant="h3" sx={{ fontSize: '30px', color: '#333', fontWeight: '600' }}>
                {teacher.name}
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '18px', color: '#777', marginTop: '10px' }}>
                {teacher.bio || 'No bio available.'}
              </Typography>
              <Box sx={{ marginTop: '20px' }}>
                <Typography variant="body2" sx={{ fontSize: '16px', color: '#555', fontWeight: '600' }}>
                  <strong>Languages:</strong> {teacher.languages.join(', ') || 'No languages specified.'}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '16px', color: '#555', fontWeight: '600' }}>
                  <strong>Skills:</strong> {teacher.skills.join(', ') || 'No skills listed.'}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '16px', color: '#555', fontWeight: '600' }}>
                  <strong>Referral Code:</strong> {teacher.referralCode || 'Not available'}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '16px', color: '#555', fontWeight: '600' }}>
                  <strong>Teacher ID:</strong> {teacher.uniqueId || 'Not available'}
                </Typography>
              </Box>

              {/* Rating Section */}
              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                <Rating
                  value={rating}
                  precision={0.1}
                  readOnly
                  sx={{
                    fontSize: '30px',
                    color: '#FFD700',
                    marginRight: '12px',
                  }}
                />
                <Typography variant="body1" sx={{ fontSize: '18px', color: '#555' }}>
                  {rating.toFixed(1)} ({reviews.length} reviews)
                </Typography>
              </Box>

              {/* Review Submission Section */}
              {!hasReviewed && (
                <Box sx={{ marginTop: '20px' }}>
                  <Typography variant="h5" sx={{ fontSize: '20px', fontWeight: '600' }}>
                    Submit Your Review
                  </Typography>
                  <Rating
                    value={newRating}
                    onChange={(event, newValue) => setNewRating(newValue)}
                    sx={{ marginTop: '10px' }}
                  />
                  <TextField
                    fullWidth
                    label="Review"
                    multiline
                    rows={4}
                    value={newReview}
                    onChange={(e) => setNewReview(e.target.value)}
                    sx={{ marginTop: '10px' }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: '10px' }}
                    onClick={handleSubmitReview}
                  >
                    Submit Review
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ margin: '30px 0' }} />

        {/* Reviews Section */}
        <Box sx={{ marginTop: '30px' }}>
          <Typography variant="h5" sx={{ fontSize: '24px', color: '#333', fontWeight: '600' }}>
            Reviews
          </Typography>

          {reviews.length > 0 ? (
            reviews.map((review) => (
              <Paper
                key={review._id}
                sx={{
                  backgroundColor: '#f9f9f9',
                  padding: '20px',
                  marginBottom: '20px',
                  borderRadius: '10px',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Typography variant="body1" sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                  {review.author}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '16px', color: '#555', marginTop: '10px' }}>
                  {review.review}
                </Typography>
                <Rating
                  value={review.rating}
                  precision={0.1}
                  readOnly
                  sx={{
                    fontSize: '20px',
                    color: '#FFD700',
                    marginTop: '10px',
                  }}
                />
              </Paper>
            ))
          ) : (
            <Typography variant="body2" sx={{ fontSize: '16px', color: '#777' }}>
              No reviews yet.
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default TeacherProfile;
