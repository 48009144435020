import React, { useState } from 'react';
import { Typography, List, ListItem, ListItemText, Divider, Paper, Pagination, Grid } from '@mui/material';
import { styled } from '@mui/system';

// Styling components using @mui/system's styled API
const Root = styled('div')({
  padding: '20px',
  backgroundColor: '#f5f5f5',
  minHeight: '100vh',
});

const ModuleContainer = styled('div')({
  padding: '20px',
  marginBottom: '20px',
  backgroundColor: '#ffffff',
});

const ModuleTitle = styled(Typography)({
  marginBottom: '10px',
  fontWeight: 'bold',
  color: '#1976d2',
  fontSize: '1.5rem',
  textAlign: 'center',
});

const TopicTitle = styled(Typography)({
  color: '#00796b',
  fontWeight: 'bold',
  fontSize: '1rem',
});

const TopicDescription = styled(Typography)({
  color: '#555555',
  fontSize: '0.9rem',
});

const DividerStyled = styled(Divider)({
  margin: '12px 0',
  backgroundColor: '#1976d2',
});

const PaginationContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
});

const StyledPagination = styled(Pagination)({
  '& .MuiPaginationItem-root': {
    fontSize: '1rem',
    color: '#1976d2',
    '&:hover': {
      backgroundColor: '#1976d2',
      color: 'white',
    },
  },
});

const ShowCurriculum = ({ curriculumData }) => {
  const [page, setPage] = useState(1);

  if (!curriculumData || !curriculumData.modules) {
    return <Typography variant="h6" align="center">Loading or no data available...</Typography>;
  }

  const itemsPerPage = 2; // Adjust the number of modules per page for readability
  const startIndex = (page - 1) * itemsPerPage;
  const selectedModules = curriculumData.modules.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Root>
      <Grid container spacing={4} justifyContent="center">
        {selectedModules.map((module) => (
          <Grid item xs={12} key={module.id}>
            <ModuleContainer>
              <ModuleTitle>{module.title}</ModuleTitle>
              {module.subtitle && <Typography align="center">{module.subtitle}</Typography>}
              {module.topics.map((topic) => (
                <div key={topic.number} style={{ marginBottom: '15px' }}>
                  <TopicTitle>{`${topic.number}. ${topic.title}`}</TopicTitle>
                  <TopicDescription>{topic.description || 'No description available'}</TopicDescription>
                  <DividerStyled />
                </div>
              ))}
            </ModuleContainer>
          </Grid>
        ))}
      </Grid>

      <PaginationContainer>
        <StyledPagination
          count={Math.ceil(curriculumData.modules.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
        />
      </PaginationContainer>
    </Root>
  );
};

export default ShowCurriculum;