import React from 'react';
import { Box, Typography, Card, CardContent, Button, LinearProgress } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '50px',
  border: '2px solid #00d8ff', // Glowing border
  color: '#00d8ff',
  padding: '8px 32px',
  textTransform: 'none',
  position: 'relative',
  backgroundColor: 'transparent',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(0, 216, 255, 0.1)',
    boxShadow: '0 0 10px #00d8ff',
    transform: 'scale(1.05)',
  },
  '&:before, &:after': {
    content: '"•"',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '20px',
    color: '#00d8ff',
  },
  '&:before': {
    left: '10px',
  },
  '&:after': {
    right: '10px',
  },
  '& .MuiButton-label': {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const CardComponent = ({ 
  imageUrl, 
  title, 
  description, 
  duration, 
  enrollments, 
  teacher, 
  pdfUrl, 
  startDate, 
  bookedClasses, 
  progress, 
  completed,
  joinUrl 
}) => {
  const navigate = useNavigate();

  const handleViewPdf = () => {
    navigate('/view-pdf', { state: { fileUrl: pdfUrl } });
  };

  const progressPercentage = (bookedClasses > 0 && progress != null)
    ? (progress / bookedClasses) * 100
    : 0;

  const isJoinDisabled = bookedClasses === progress; // Check if join should be disabled

  return (
    <Card
      sx={{
        borderRadius: '16px',
        minHeight: '450px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        backgroundColor: '#ffffff', // White background
        '&:hover': {
          boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
          transform: 'scale(1.05)',
        },
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <img
          src={imageUrl}
          alt={title}
          style={{
            width: '100%',
            height: '220px',
            objectFit: 'cover',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
          }}
        />
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3))',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Typography variant="h5" sx={{ color: '#00d8ff', fontWeight: 'bold', textAlign: 'center' }}>
            {title}
          </Typography>
        </Box>
      </Box>

      <CardContent sx={{ padding: '16px' }}>
        <Typography variant="body2" sx={{ color: '#333' }}>{description}</Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <AccessTimeIcon sx={{ fontSize: 16, mr: 1, color: '#00d8ff' }} />
          <Typography variant="body2" sx={{ color: '#333' }}>
            Duration: 60 minutes
          </Typography>
        </Box>
        
        <Box sx={{ mt: 1 }}>
          <Typography variant="body2" sx={{ color: '#333' }}>
            Total Classes: {bookedClasses}
          </Typography>
          <Typography variant="body2" sx={{ color: '#333' }}>
            Teacher: {teacher || 'Not Assigned'}
          </Typography>
          <Typography variant="body2" sx={{ color: '#333' }}>
            Start Date: {startDate ? new Date(startDate).toLocaleDateString() : 'N/A'}
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" sx={{ color: '#333' }}>
            Progress: {progressPercentage.toFixed(2)}%
          </Typography>
          <LinearProgress 
            variant="determinate" 
            value={progressPercentage} 
            sx={{
              mt: 1,
              '& .MuiLinearProgress-bar': {
                background: '#00d8ff',
                boxShadow: '0px 0px 10px rgba(0, 216, 255, 0.5)',
              },
            }} 
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" sx={{ color: '#333' }}>
            Completed: {completed ? 'Yes' : 'No'}
          </Typography>
        </Box>

        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {pdfUrl && (
            <StyledButton
              variant="outlined"
              color="primary"
              onClick={handleViewPdf}
              sx={{ mb: 2 }}
            >
              View Course Material
            </StyledButton>
          )}

          {joinUrl && ( // Render Join Class button if joinUrl exists
            <StyledButton
              variant="contained"
              color="secondary"
              href={!isJoinDisabled ? joinUrl : undefined} // Link to join class or undefined if disabled
              target="_blank"
              sx={{
                opacity: isJoinDisabled ? 0.5 : 1,
                pointerEvents: isJoinDisabled ? 'none' : 'auto',
                mb: 2,
              }} // Disable interaction if needed
              disabled={isJoinDisabled} // Disabled button when joinUrl is not available or progress is complete
            >
              Join Class
            </StyledButton>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
