import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Card, CardContent, Typography, Grid, Avatar, IconButton, Button } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import axios from 'axios';  // Make sure to install axios: npm install axios
import baseUrl from '../config';
import { lightBlue, deepPurple, amber, green } from '@mui/material/colors';

function Assessments() {
  const [quizResults, setQuizResults] = useState([]);
  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user);

  useEffect(() => {
    const fetchQuizResults = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/students/assessment/${parsedUser.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setQuizResults(response.data.quizResults);  // Set quiz results in state
      } catch (error) {
        console.error('Error fetching quiz results:', error);
      }
    };

    fetchQuizResults();
  }, [parsedUser.id]);

  const getTimeRemaining = (linkAssignedAt) => {
    const assignedAt = new Date(linkAssignedAt);
    if (isNaN(assignedAt)) {
      console.error('Invalid linkAssignedAt:', linkAssignedAt);
      return null;
    }

    const now = new Date();
    const timeLeft = assignedAt.getTime() + 2 * 60 * 60 * 1000 - now.getTime(); // 2 hours in milliseconds

    if (timeLeft <= 0) return 0;

    const hours = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    return { hours, minutes, seconds };
  };

  const handleQuizClick = async (quizIndex, quizUrl) => {
    try {
      await axios.post(`${baseUrl}api/students/mark-quiz-attended`, {
        studentId: parsedUser.id,
        quizIndex: quizIndex,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });

      const updatedQuizResults = [...quizResults];
      updatedQuizResults[quizIndex].quizPassed = 'Pending'; // Mark as pending
      updatedQuizResults[quizIndex].performance = 'Attended, awaiting feedback';
      setQuizResults(updatedQuizResults);

      // Open the quiz in a new tab
      window.open(quizUrl, '_blank');
      
    } catch (error) {
      console.error('Error marking quiz as attended:', error);
      alert('Error marking quiz as attended');
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 4,
        backgroundColor: '#F5F5F5',
        minHeight: '100vh',
      }}
    >
      {/* Back button and header */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
        <IconButton sx={{ mr: 2 }}>
          <ArrowBackIcon sx={{ fontSize: '30px', color: deepPurple[500] }} />
        </IconButton>
        <Box>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#333', fontSize: '2rem' }}>
            Your Assessments
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ marginTop: 1, fontSize: '1.1rem' }}>
            Let’s gear up to be future ready
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
      </Box>

      <Grid container spacing={4}>
        {quizResults.length > 0 ? (
          quizResults.map((quiz, index) => {
            const timeRemaining = getTimeRemaining(quiz.linkAssignedAt);
            const isTimeExpired = timeRemaining === 0;

            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    backgroundColor: '#fafafa',
                    color: '#333',
                    borderRadius: '16px',
                    textAlign: 'center',
                    padding: '20px',
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0px 15px 25px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                >
                  <CardContent>
                    <Avatar
                      sx={{
                        margin: 'auto',
                        background: 'linear-gradient(135deg, #FF5E62, #FF9966)',
                        width: 80,
                        height: 80,
                      }}
                    >
                      <LockIcon sx={{ color: 'white', fontSize: '40px' }} />
                    </Avatar>
                    <Typography variant="h5" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
                      Quiz {index + 1}
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: '8px', fontSize: '1rem' }}>
                      Score: {quiz.quizScore !== null ? quiz.quizScore : 'Not Taken'}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '8px', fontSize: '0.9rem', color: '#666' }}>
                      Quiz Date: {quiz.quizDate !== null ? quiz.quizDate : 'Not Taken'}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '8px', fontSize: '0.9rem', color: '#666' }}>
                      Status: {quiz.quizPassed === 'Pending' ? 'Pending' : quiz.quizPassed ? 'Passed' : 'Failed'}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '12px', fontStyle: 'italic', fontSize: '0.9rem' }}>
                      {quiz.performance || 'No feedback yet'}
                    </Typography>

                    {!isTimeExpired && timeRemaining && (
                      <Typography variant="body2" sx={{ marginTop: '12px', color: amber[700], fontSize: '1rem' }}>
                        Time remaining: {timeRemaining.hours}h {timeRemaining.minutes}m {timeRemaining.seconds}s
                      </Typography>
                    )}

                    {isTimeExpired && (
                      <Typography variant="body2" sx={{ marginTop: '12px', color: 'red', fontSize: '1rem' }}>
                        Time expired. Quiz failed.
                      </Typography>
                    )}

                    {quiz.quizLink && quiz.quizPassed === 'Pending' && !isTimeExpired && (
                      <Button
                        onClick={() => handleQuizClick(index, quiz.quizLink)}
                        variant="contained"
                        sx={{
                          marginTop: '20px',
                          backgroundColor: lightBlue[600],
                          '&:hover': {
                            backgroundColor: lightBlue[800],
                          },
                          padding: '10px 20px',
                          fontSize: '1rem',
                        }}
                      >
                        Start Quiz
                      </Button>
                    )}

                    {quiz.quizPassed !== 'Pending' && quiz.quizLink && (
                      <Typography variant="body2" sx={{ marginTop: '20px', color: green[600], fontSize: '1rem' }}>
                        Quiz Attended - {quiz.performance || 'Awaiting Teacher Feedback'}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            );
          })
        ) : (
          <Typography variant="body2" sx={{ textAlign: 'center', width: '100%', fontSize: '1.2rem', color: '#888' }}>
            No assessments available or data is loading...
          </Typography>
        )}
      </Grid>
    </Box>
  );
}

export default Assessments;
