import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Chip,
  Divider,
  Paper,
  Autocomplete,
} from '@mui/material';
import { PhotoCamera, Info, FamilyRestroom, Language, Work } from '@mui/icons-material';
import axios from 'axios';
import baseUrl from '../config'; // your API base URL
import countries from '../utlis'; // List of countries
import { CalendarToday, Person } from '@mui/icons-material';

const StudentProfilePage = () => {
  const [profile, setProfile] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    avatar: '',
    bio: '',
    skills: [],
    languages: [],
    dob: '',
    gender: '',
    parentName: '',
    nationality: null, // Set nationality as null initially
  });
  const [avatarFile, setAvatarFile] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await axios.get(`${baseUrl}api/students/profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setProfile(data);
        setFormData({
          name: data.name,
          avatar: data.avatar,
          bio: data.bio,
          skills: data.skills || [],
          languages: data.languages || [],
          dob: data.dob || '',
          gender: data.gender || '',
          nationality: data.nationality || null, // Handle nationality correctly
          parentName: data.parentName || '', // Handle parent name
        });
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };
    fetchProfile();
  }, []);

  const handleAvatarChange = (e) => {
    setAvatarFile(e.target.files[0]);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = new FormData();
    updatedFormData.append('name', formData.name);
    updatedFormData.append('bio', formData.bio);
    updatedFormData.append('languages', formData.languages.join(', '));
    updatedFormData.append('skills', formData.skills.join(', '));
    updatedFormData.append('dob', formData.dob);
    updatedFormData.append('gender', formData.gender);
    updatedFormData.append('parentName', formData.parentName); // Include parent name
    updatedFormData.append('nationality', formData.nationality?.name || ''); // Send country name for the API
    if (avatarFile) updatedFormData.append('avatar', avatarFile);

    try {
      const { data } = await axios.put(`${baseUrl}api/students/profile`, updatedFormData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setProfile(data);
      setEditModalOpen(false);
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile');
    }
  };

  if (!profile) {
    return <Typography variant="h6" align="center" color="primary">Loading...</Typography>;
  }

  return (
    <Box sx={{ p: 4, backgroundColor: '#f7f7f9' }}>
        {/* Profile Section */}
        <Grid container spacing={4} justifyContent="center">
  {/* Profile Section */}
  <Grid item xs={12} sm={8} md={6}>
  <Card sx={{ borderRadius: 3, boxShadow: 8, backgroundColor: '#ffffff' }}>
    <CardContent>
      {/* Profile Header with Avatar on Left and Name & Edit Button on Right */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        flexDirection: { xs: 'column', sm: 'row' },  // Stack vertically on small screens
        justifyContent: 'center',
        mb: 3 
      }}>
        {/* Avatar on the left side */}
        <Avatar
          src={avatarFile ? URL.createObjectURL(avatarFile) : `${baseUrl}${profile.avatar || ''}`}
          alt={formData.name}
          sx={{
            width: 250,  // Larger Avatar size
            height: 250,
            mb: { xs: 2, sm: 0 },  // Add margin at the bottom for small screens
            mr: { sm: 3 },         // Keep the margin for larger screens
            border: '5px solid #00796b',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s ease-in-out',
            '&:hover': { transform: 'scale(1.1)' },
          }}
        >
          {!avatarFile && !profile.avatar && formData.name[0]} {/* Fallback initials */}
        </Avatar>

        {/* Name and Edit Button on the right side */}
        <Box sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
          <Typography variant="h4" sx={{ fontWeight: 600, color: '#333', mb: 1 }}>
            {formData.name}
          </Typography>
          
          <Button
            variant="contained"
            sx={{
              color: '#fff',
              backgroundColor: '#00796b',
              '&:hover': { backgroundColor: '#004d40' },
              paddingX: 4,
              paddingY: 1.5,
              fontWeight: 600,
              borderRadius: 20,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)', 
              display: { xs: 'block', sm: 'inline-block' }, // Make it block on small screens
              marginTop: { xs: 2, sm: 0 },  // Adjust the top margin for small screens
            }}
            onClick={() => setEditModalOpen(true)}
          >
            Edit Profile
          </Button>
        </Box>
      </Box>

      <Divider sx={{ mb: 3 }} />

      {/* Profile Information Section */}
      <Grid container spacing={2}>
        {/* Date of Birth */}
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CalendarToday sx={{ color: '#00796b', marginRight: 1 }} />
            <Typography variant="h6" color="textSecondary" fontWeight="bold">Date of Birth:</Typography>
          </Box>
          <Typography variant="body1">{formData.dob}</Typography>
        </Grid>

        {/* Gender */}
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Person sx={{ color: '#00796b', marginRight: 1 }} />
            <Typography variant="h6" color="textSecondary" fontWeight="bold">Gender:</Typography>
          </Box>
          <Typography variant="body1">{formData.gender}</Typography>
        </Grid>

        {/* Nationality */}
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Language sx={{ color: '#00796b', marginRight: 1 }} />
            <Typography variant="h6" color="textSecondary" fontWeight="bold">Nationality:</Typography>
          </Box>
          <Typography variant="body1">{formData.nationality?.name || 'Not selected'}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FamilyRestroom sx={{  color: '#00796b',verticalAlign: 'middle', marginRight: 1 }} />
            <Typography variant="h6" color="textSecondary" fontWeight="bold">Family:</Typography>
          </Box>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 2, fontSize: 16 }}>
            Parent's Name: {formData.parentName || 'Not provided'}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6" sx={{ fontWeight: 700, mt: 3, mb: 2, color: '#333' }}>Skills & Languages</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
            {formData.skills.map((skill) => (
              <Chip key={skill} label={skill} color="primary" variant="outlined" sx={{ fontWeight: 600, mb: 1 }} />
            ))}
          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {formData.languages.map((lang) => (
              <Chip key={lang} label={lang} color="secondary" variant="outlined" sx={{ fontWeight: 600, mb: 1 }} />
            ))}
          </Box> 
        </Grid>
      </Grid>

    </CardContent>
  </Card>
</Grid>


  {/* Bio Section */}
  <Grid item xs={12} sm={8} md={4}>
    <Card sx={{ borderRadius: 3, boxShadow: 6, backgroundColor: '#ffffff' }}>
      <CardContent>
        <Typography variant="h5" sx={{ fontWeight: 700, mb: 2, color: '#00796b' }}>About Me</Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mb: 2, fontSize: 16 }}>
          <Info sx={{ verticalAlign: 'middle', marginRight: 1 }} />
          {formData.bio || 'No bio available.'}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
</Grid>


     

      {/* Edit Profile Modal */}
      <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <label htmlFor="avatar-upload">
                <IconButton
                  color="primary"
                  component="span"
                  sx={{
                    position: 'absolute',
                    top: 90,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 10,
                  }}
                >
                  <PhotoCamera />
                </IconButton>
                <input
                  type="file"
                  id="avatar-upload"
                  accept="image/*"
                  hidden
                  onChange={handleAvatarChange}
                />
                <Box
                  sx={{
                    width: 120,
                    height: 120,
                    borderRadius: '50%',
                    border: '2px solid #00796b',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#f0f0f0',
                    mb: 2,
                  }}
                >
                  <Avatar
                    src={avatarFile ? URL.createObjectURL(avatarFile) : `${baseUrl}${formData.avatar}`}
                    sx={{ width: 120, height: 120 }}
                  />
                </Box>
              </label>
            </Box>

            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                style: { fontWeight: 600 },
              }}
            />
            <TextField
              label="Bio"
              name="bio"
              value={formData.bio}
              onChange={handleFormChange}
              fullWidth
              multiline
              rows={3}
              margin="normal"
              InputLabelProps={{
                style: { fontWeight: 600 },
              }}
            />
            <TextField
              label="Parent Name"
              name="parentName"
              value={formData.parentName}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                style: { fontWeight: 600 },
              }}
            />
            <TextField
              label="Languages (comma separated)"
              name="languages"
              value={formData.languages.join(', ')}
              onChange={(e) => setFormData({ ...formData, languages: e.target.value.split(',').map((l) => l.trim()) })}
              fullWidth
              margin="normal"
              InputLabelProps={{
                style: { fontWeight: 600 },
              }}
            />
            <TextField
              label="Skills (comma separated)"
              name="skills"
              value={formData.skills.join(', ')}
              onChange={(e) => setFormData({ ...formData, skills: e.target.value.split(',').map((s) => s.trim()) })}
              fullWidth
              margin="normal"
              InputLabelProps={{
                style: { fontWeight: 600 },
              }}
            />
            <TextField
              label="Date of Birth"
              name="dob"
              type="date"
              value={formData.dob}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
                style: { fontWeight: 600 },
              }}
            />
            <TextField
              label="Gender"
              name="gender"
              value={formData.gender}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
              InputLabelProps={{
                style: { fontWeight: 600 },
              }}
            />
            <Autocomplete
              options={countries}
              getOptionLabel={(option) => option.name}
              value={formData.nationality || null}
              onChange={(event, newValue) => setFormData({ ...formData, nationality: newValue || null })}
              renderInput={(params) => <TextField {...params} label="Nationality" fullWidth />}
              margin="normal"
            />

            <DialogActions>
              <Button onClick={() => setEditModalOpen(false)} color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Update Profile
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default StudentProfilePage;
