import React from "react";
import { Box, useMediaQuery } from '@mui/material'; // Importing useMediaQuery
import SidebarRight from "../Components/SideScreen";
import Dashboards from "../Components/Dashboard";

const DashboardPage = () => {
  // Use media queries to check both width and height
  const isTablet = useMediaQuery('(max-width:1024px)'); // If the screen width is smaller than 1024px
  const isSmallHeight = useMediaQuery('(max-height:1366px)'); // If the screen height is smaller than 1366px

  // Conditionally hide SidebarRight if it's a small screen size (based on width or height)
  const shouldHideSidebar =  isTablet&&isSmallHeight;

  return (
    <Box
      display="flex"
      height="100vh"
      sx={{     paddingTop: { xs: '20px',sm:'70px', md:'70px',lg: 0 },  // Apply paddingTop only on small screens (mobile)
      // This will create space below the fixed header
      }}
      flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} // Column for smaller screens and row for larger screens
    >
      <Dashboards />

      {/* Conditionally render SidebarRight only for larger screens (not hidden on small screens) */}
      {!shouldHideSidebar && <SidebarRight />}
    </Box>
  );
};

export default DashboardPage;
