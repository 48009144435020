import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Badge,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import baseUrl from '../config';
import LockIcon from '@mui/icons-material/Lock';
import Confetti from 'react-confetti';
import { format } from 'date-fns';
import StarIcon from '@mui/icons-material/Star';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const MyCertificates = ({ studentId }) => {
  const [unlockedBatches, setUnlockedBatches] = useState([]);
  const [lockedBatches, setLockedBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [celebrationVisible, setCelebrationVisible] = useState(false);
  const [studentData, setStudentData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const navigate = useNavigate(); // Initialize navigate

  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user);

  useEffect(() => {
    const fetchStudentAchievements = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/students/achievment/${parsedUser.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (response.data) {
          setStudentData(response.data);
          setUnlockedBatches(response.data.unlockedBatches);
          setLockedBatches(response.data.lockedBatches);
        }
      } catch (error) {
        console.error('Error fetching student achievements:', error);
      }
    };

    fetchStudentAchievements();
  }, [studentId]);

  const getBatchImage = (batchId) => {
    try {
      return require(`../Assets/batch/${batchId}.png`);
    } catch (error) {
      return '';
    }
  };

  const handleBatchClick = (batch) => {
    setSelectedBatch(batch);
    setCelebrationVisible(true);
    setTimeout(() => setCelebrationVisible(false), 5000);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'MMMM dd, yyyy');
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: 4,
        backgroundColor: '#FAFAFA',
        minHeight: '100vh',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <IconButton sx={{ mr: 2 }} onClick={() => navigate(-1)}>
          <ArrowBackIcon sx={{ fontSize: '1.5rem', color: '#1976D2' }} />
        </IconButton>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
            My Achievements
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ marginTop: 1, fontSize: '1.1rem' }}>
            Earn Batches by completing milestones
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
      </Box>

      {/* Show message if no unlocked batches */}
      {unlockedBatches.length === 0 ? (
        <Box sx={{ textAlign: 'center', padding: 4, backgroundColor: '#FFEBEE', borderRadius: '8px' }}>
          <Typography variant="h6" sx={{ color: '#D32F2F', fontWeight: 'bold' }}>
            You have not unlocked any batches yet!
          </Typography>
          <Typography variant="body1" sx={{ color: '#757575', marginTop: 2 }}>
            Don't worry! After completing just 5 classes, you'll unlock your first batch and reach an exciting milestone!
          </Typography>
        </Box>
      ) : (
        <>
          <Typography variant="h5" sx={{ color: '#43A047', fontWeight: 'bold', marginBottom: 3 }}>
            Unlocked Batches
          </Typography>
          <Grid container spacing={3}>
            {unlockedBatches.map((batch) => (
              <Grid item xs={12} sm={6} md={3} key={batch.batchId}>
                <Badge
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  overlap="circular"
                  badgeContent={<StarIcon sx={{ color: '#FFD700', fontSize: '1.5rem' }} />}
                >
                  <Card
                    sx={{
                      borderRadius: '16px',
                      boxShadow: 5,
                      background: '#F8F8F8',
                      cursor: 'pointer',
                      position: 'relative',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow: 10,
                      },
                      transition: 'all 0.3s ease',
                    }}
                    onClick={() => handleBatchClick(batch)}
                  >
                    {celebrationVisible && (
                      <Confetti
                        width={windowWidth}
                        height={windowHeight}
                        numberOfPieces={200}
                        gravity={0.3}
                        recycle={false}
                      />
                    )}

                    <img
                      src={getBatchImage(batch.batchId)}
                      alt={batch.title}
                      style={{
                        width: '80%',
                        height: '60%',
                        objectFit: 'contain',
                        display: 'block',
                        margin: '20px auto',
                        borderRadius: '10px',
                      }}
                    />

                    <CardContent sx={{ padding: 2 }}>
                      <Typography variant="h6" sx={{ fontWeight: '600', color: '#333' }}>
                        {batch.title}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#757575', marginTop: 1 }}>
                        Milestone: {batch.milestone} classes
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#757575', marginTop: 1 }}>
                        Awarded On: {formatDate(batch.dateAssigned)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Badge>
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <Divider sx={{ marginY: 4 }} />

      <Typography variant="h5" sx={{ color: '#FF5722', fontWeight: 'bold', marginBottom: 3 }}>
        Locked Batches
      </Typography>

      <Grid container spacing={3}>
        {lockedBatches.map((batch) => (
          <Grid item xs={12} sm={6} md={4} key={batch.batchId}>
            <Card
              sx={{
                borderRadius: '16px',
                boxShadow: 3,
                backgroundColor: '#616161',
                cursor: 'pointer',
                '&:hover': {
                  boxShadow: 6,
                },
              }}
            >
              <CardContent sx={{ textAlign: 'center', padding: 3 }}>
                <Avatar
                  sx={{
                    margin: 'auto',
                    backgroundColor: '#424242',
                    width: 56,
                    height: 56,
                    marginBottom: 2,
                  }}
                >
                  <LockIcon sx={{ color: 'white' }} />
                </Avatar>

                <Typography variant="h6" sx={{ fontWeight: '600', color: 'white' }}>
                  {batch.title}
                </Typography>
                <Typography variant="body2" sx={{ color: '#BDBDBD' }}>
                  Milestone: {batch.milestone} classes
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {selectedBatch && (
        <Dialog
          open={Boolean(selectedBatch)}
          onClose={() => setSelectedBatch(null)}
          sx={{
            '& .MuiDialog-paper': {
              padding: 4,
              borderRadius: '20px',
              background: 'linear-gradient(135deg, #1E88E5, #43A047)',
              color: '#fff',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
              textAlign: 'center',
              width: '90%',
              maxWidth: '500px',
            },
          }}
        >
          <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.8rem' }}>Congratulations!</DialogTitle>

          <DialogContent>
            <img
              src={getBatchImage(selectedBatch.batchId)}
              alt={selectedBatch.title}
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
                marginBottom: '20px',
                borderRadius: '12px',
              }}
            />

            <Typography variant="h6" sx={{ fontWeight: '600', marginBottom: 2 }}>
              You've unlocked the "{selectedBatch.title}" batch!
            </Typography>

            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              Milestone: {selectedBatch.milestone} classes
            </Typography>

            <Typography variant="body2">
              Awarded On: {formatDate(selectedBatch.dateAssigned)}
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => setSelectedBatch(null)}
              sx={{
                backgroundColor: '#fff',
                color: '#1E88E5',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#e3f2fd',
                },
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default MyCertificates;
