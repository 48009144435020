import React from 'react';
import { Outlet } from 'react-router-dom';
import ManubarPage from './Pages/ManubarPage';
import { Box, useMediaQuery, useTheme } from '@mui/material';

const Layout = () => {
  // Using MUI's useTheme and useMediaQuery for responsiveness
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', height: '100vh' }}>
      {/* Sidebar (ManubarPage) */}
      <ManubarPage isMobile={isMobile} />
      
      {/* Main content area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          paddingTop: isMobile ? '60px' : '0px', // Padding to avoid content being hidden under the mobile menu
        }}
      >
        {/* Render child route content */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
